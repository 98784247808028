function access(module, buildingList) {
  if (module) {
    const buildingAccessIds = buildingList.map((building) => building.id)
    const buildingNewIds = (module.buildings || []).map(
      (building) => building.id
    )

    return buildingNewIds.every((item) => buildingAccessIds.includes(item))
  }
  return false
}

export { access }
